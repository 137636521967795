import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react'

const BurgerMenu = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    onClick={onClick}
    aria-label="menu"
    variant="unstyled"
    ml="-3" // align to icon not the box
    icon={
      <Icon viewBox="0 0 100 80" width="20" height="12" boxSize={5} fill="white">
        <rect width="100" height="12" rx="8"></rect>
        <rect y="30" width="100" height="12" rx="8"></rect>
        <rect y="60" width="100" height="12" rx="8"></rect>
      </Icon>
    }
  />
)
export default BurgerMenu
