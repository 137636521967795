import ButtonLink from '@/components/ButtonLink'
import PageContainer from '@/components/PageContainer'
import useAuth from '@/hooks/useAuth'
import routes from '@/routes'
import { Box, Center, Flex, Heading, Spacer, Text, VStack } from '@packages/ui-components'
import { NextPage } from 'next'
import CoverImage from 'public/images/bg-cover-404.jpg'
import React from 'react'

interface Props {
  children?: React.ReactNode
  title: string
  subTitle: string
  errorCode: number
  text?: React.ReactNode
}

export const DashboardButton = () => (
  <ButtonLink
    href={routes.dashboard}
    background="blue.800"
    textTransform="uppercase"
    size="lg"
    fontSize="14px"
    borderRadius="4px"
    textDecoration="none !important"
  >
    Return to Dashboard
  </ButtonLink>
)

const ErrorPageLayout: NextPage<Props> = ({ title, subTitle, children, text, errorCode }) => {
  const auth = useAuth()
  
  return (
    <PageContainer title={'Error ' + errorCode} collapse showNav={auth.isLoggedIn}>
      <Center
        bgImage={`url('${CoverImage}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        width="100%"
        h="64"
      >
        <VStack p={6} spacing={1} color="white" textAlign="center">
          <Heading fontSize="40px" lineHeight="1" letterSpacing="-3px">
            {title}
          </Heading>
          <Spacer />
          <Heading fontSize="40px" lineHeight="1" letterSpacing="-3px" textTransform="uppercase">
            {subTitle}
          </Heading>
        </VStack>
      </Center>
      <Box px="6" py="8">
        <Text opacity="0.7" fontSize="md" textAlign="center">        
            { text }
        </Text>
        {children}
      </Box>
      <Flex align="stretch" w="full" justifyContent="center">
        <DashboardButton />
      </Flex>
    </PageContainer>
  )
}

export default ErrorPageLayout
