import { Icon, IconProps, useToken } from '@chakra-ui/react'

const AvatarRounded = (props: IconProps) => {
  const [blue400, blue800, white] = useToken('colors', ['blue.400', 'blue.800', 'white'])
  return (
    <Icon viewBox="0 0 512 512" color={blue800} {...props}>
      <circle cx="256" cy="256" r="250" fill={white} />
      <path
        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm20.2 128.4c35.8 0 64.9 29 64.9 64.9 0 35.8-29 64.9-64.9 64.9a64.9 64.9 0 110-129.8zm9.2 298.5c-79.4 0-137.5-37.8-137.5-66.4s61.5-69.4 137.5-69.4 137.5 40.8 137.5 69.4c-.1 28.7-58.2 66.4-137.5 66.4z"
        fill={blue400}
      />
      <path
        fill="none"
        stroke={blue800}
        strokeWidth="18"
        d="M256 232.4c34.6 0 62.7-28.1 62.7-62.7S290.6 107 256 107s-62.7 28.1-62.7 62.7 28.1 62.7 62.7 62.7zM393.5 345.1c0 28.7-58.1 66.4-137.5 66.4s-137.4-37.7-137.4-66.4c0-28.7 61.5-69.4 137.5-69.4s137.4 40.8 137.4 69.4z"
      />
    </Icon>
  )
}
export default AvatarRounded
