import useLocalStorage from './useLocalStorage'

type ErrorType = {
  error: string
  module: string
}

export const useXplanSyncErrors = () => {
  const [xplanSyncErrors, setXplanSyncErrors, deleteXplanSyncErrors] = useLocalStorage<
    ErrorType[] | undefined
  >('xplanSyncErrors', undefined)

  return {
    xplanSyncErrors,
    setXplanSyncErrors,
    deleteXplanSyncErrors,
  }
}
