import { graphql } from 'react-relay'

export default graphql`
  query indexPage_indexQuery {
    me {
      id
      currentXplanClientId
      firstName
    }
  }
`
