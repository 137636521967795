import React from 'react'

import { Link, LinkProps } from '@chakra-ui/react'

export default function ExLink({ href, children, ...rest }: LinkProps) {
  return (
    <Link href={href} isExternal {...rest}>
      {children}
    </Link>
  )
}
