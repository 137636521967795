import routes from '@/routes'
import { indexPage_indexQuery } from '@/__generated__/indexPage_indexQuery.graphql'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { graphql, useMutation, useQuery } from 'relay-hooks'
import indexPageQuery from '@/queries/indexPage'
import { isFalsy } from 'utility-types'
import { GraphQLTaggedNode } from 'relay-runtime'

const useAuthSignOutMutation = graphql`
  mutation useAuthSignOutMutation {
    signOut {
      __typename
      ... on SignOutErrorPayload {
        error {
          message
        }
      }
      ... on SignOutSuccessPayload {
        success
      }
    }
  }
`

const useAuth = () => {
  const router = useRouter()

  const [mutate] = useMutation(useAuthSignOutMutation)

  const { data, isLoading, error } = useQuery<indexPage_indexQuery>(
    indexPageQuery as GraphQLTaggedNode,
    undefined,
    {
      fetchPolicy: 'network-only',
    }
  )

  // We need to store this in a ref so we don't infinite loop when
  // calling mutate causes a rerender
  const logoutRef = useRef<{ loggingOut: boolean; logout: () => void }>()

  if (!logoutRef.current) {
    logoutRef.current = {
      loggingOut: false,
      logout: () => {
        if (logoutRef.current && !logoutRef.current.loggingOut) {
          logoutRef.current.loggingOut = true
          mutate({ variables: {} })
          router.push(routes.login)
        }
      },
    }
  }

  return {
    isLoading,
    isLoggedIn: !isFalsy(!isLoading && !error && data && data.me),
    logout: () => logoutRef.current?.logout(), // Will never be undefined,
  }
}

export default useAuth
