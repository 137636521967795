import { PropsWithChildren } from 'react'
import NextLink from 'next/link'
import { LinkProps as NextLinkProps } from 'next/dist/client/link'
import { ButtonProps } from '@chakra-ui/react'
import { ChakraButton } from '@packages/ui-components'

export type ButtonLinkProps = PropsWithChildren<
  NextLinkProps & Omit<ButtonProps, 'as'> & { newTab?: boolean }
>

//  Has to be a new component because both chakra and next share the `as` keyword
export const ButtonLink = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  newTab,
  ...chakraProps
}: ButtonLinkProps) => {
  return (
    <NextLink
      passHref={true}
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
    >
      <ChakraButton as="a" {...chakraProps} target={newTab ? '_blank' : undefined} />
    </NextLink>
  )
}
export default ButtonLink
