import { useXplanSyncErrors } from '@/hooks/useXplanSyncError'
import { Flex, FlexProps, Header } from '@packages/ui-components'
import Head from 'next/head'
import React from 'react'
import { useWindowSize } from 'react-use'
import Drawer from '../components/Drawer'
import ErrorPageLayout from './ErrorPage'
interface PageContainer extends FlexProps {
  title: string
  children: React.ReactNode
  showNav?: boolean
  collapse?: boolean
  headerRight?: React.ReactNode
  trackWindowWidth?: boolean
}
export default function PageContainer({
  title,
  children,
  showNav = true,
  headerRight = null,
  collapse = false,
  trackWindowWidth = false,
  ...props
}: PageContainer) {
  const startComponent = showNav ? <Drawer /> : null

  const windowSize = useWindowSize()
  const windowWidth = Math.min(windowSize.width, 672)

  const { xplanSyncErrors } = useXplanSyncErrors()
  const error = xplanSyncErrors?.some((error) => error.module === title)

  if (error) {
    return (
      <ErrorPageLayout
        title="Error"
        subTitle=" Oops, that didn’t work."
        errorCode={500}
        text={
          <>
            Apologies, we&apos;ve had an error displaying this information. Sometimes a good
            ol&apos; refresh does the job, but if not please send us a message on{' '}
            <a
              href="mailto:support@lifetime.co.nz?subject=MyLifetime App Issue"
              style={{ textDecoration: 'underline' }}
            >
              support@lifetime.co.nz
            </a>{' '}
            and we&apos;ll jump straight onto this for you.
          </>
        }
      />
    )
  }

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>
      {/* @ts-expect-error - react versions */}
      <Header startComponent={startComponent} endComponent={headerRight}>
        {title}
      </Header>

      {collapse ? (
        <Flex flexDirection="column" flex={1}>
          {children}
        </Flex>
      ) : (
        <Flex
          width={trackWindowWidth ? windowWidth : undefined}
          flexDirection="column"
          flex={1}
          p="6"
          {...props}
        >
          {children}
        </Flex>
      )}
    </React.Fragment>
  )
}
