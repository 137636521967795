import AvatarRounded from '@/components/icons/AvatarRounded'
import BurgerMenu from '@/components/icons/BurgerMenu'
import routes from '@/routes'
import { DrawerQuery } from '@/__generated__/DrawerQuery.graphql'
import { Drawer_me$key } from '@/__generated__/Drawer_me.graphql'
import {
  Avatar,
  Box,
  Center,
  Drawer as DrawerUI,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  useDisclosure,
} from '@packages/ui-components'
import CoverImage from 'public/images/bg-cover-07.jpg'
import React from 'react'
import { graphql, useFragment, useQuery } from 'relay-hooks'
import ExLink from '../ExLink'
import Link from '../Link'
interface HoverBoxProps {
  children: string
  icon: string
  altText: string
}
const HoverBox = ({ children, icon, altText }: HoverBoxProps) => (
  <Flex
    w="100%"
    _hover={{ background: 'blue.100' }}
    _focus={{ background: 'blue.100' }}
    pl="4"
    py="3"
    textTransform="capitalize"
    fontSize="sm"
    alignItems="center"
    borderRadius="base"
  >
    <Image src={`/icons/${icon}.svg`} alt={altText} h="6" w="10" px="2" color="transparent" />
    <Box pl="4">{children}</Box>
  </Flex>
)
const menuItems = [
  { url: routes.dashboard, menuText: 'Dashboard', svg: 'poll' },
  { url: routes.investment, menuText: 'Investments', svg: 'investment' },
  { url: routes.insurance, menuText: 'Insurance', svg: 'umbrella' },
  { url: routes.goals, menuText: 'Goals', svg: 'flag' },
  { url: routes.income, menuText: 'Income', svg: 'icon-income' },
  { url: routes.assets, menuText: 'Assets', svg: 'dollar' },
  { url: routes.debts, menuText: 'Debts', svg: 'home-alt' },
  { url: routes.netwealth, menuText: 'Net Wealth', svg: 'calculator' },
  { url: routes.contactUs, menuText: 'Contact us', svg: 'comment' },
  { url: routes.logout, menuText: 'Logout', svg: 'log-out' },
]
const fragmentSpec = graphql`
  fragment Drawer_me on UserType {
    firstName
    lastName
    currentXplanClientId
    avatarUrl: profileImageUrl(width: 100, height: 100)
  }
`

interface ProfileLinkProps {
  me: Drawer_me$key
}
const ProfileLink = (props: ProfileLinkProps) => {
  const { firstName, lastName, currentXplanClientId, avatarUrl } = useFragment(
    fragmentSpec,
    props.me
  )

  return (
    <Flex
      bgColor="blue.800"
      bgImage={`linear-gradient(to right, #0002, #0002), linear-gradient(to right, #00466f50, #00466f50), url('${CoverImage}')`}
      backgroundBlendMode="normal, soft-light, normal"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      flexDirection="column"
      justifyContent="flex-end"
      color="white"
      w="100%"
      minH="40"
      p="6"
      cursor="pointer"
    >
      <Link href={routes.profile}>
        <Flex align="center">
          {avatarUrl ? <Avatar w={10} h={10} src={avatarUrl} /> : <AvatarRounded w={10} h={10} />}

          <Box ml="4">
            <Text fontSize="md">
              {firstName} {lastName}
            </Text>
            <Text fontSize="xs">Client ID: {currentXplanClientId}</Text>
          </Box>
        </Flex>
      </Link>
    </Flex>
  )
}

const query = graphql`
  query DrawerQuery {
    me {
      id
      ...Drawer_me
    }
  }
`

const Loading = () => (
  <Stack p={6}>
    <SkeletonCircle size="10" />
    <Skeleton height="20px" />
    <Skeleton height="20px" />
  </Stack>
)
const Drawer: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, isLoading } = useQuery<DrawerQuery>(query, undefined, {
    fetchPolicy: 'store-and-network',
  })
  const RenderProfile = () => {
    if (isLoading || !data || data === undefined || !data.me) {
      return <Loading />
    } else {
      return <ProfileLink me={data.me} />
    }
  }
  return (
    <>
      <BurgerMenu onClick={onOpen} />
      <DrawerUI placement={'left'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent overflow="auto">
            <DrawerHeader py="8" alignItems="center">
              <Flex alignItems="center" justifyContent="space-between">
                <Image src="/png/email-hdr-logo.png" alt="My Lifetime logo" h="36px" />
                <DrawerCloseButton />
              </Flex>
            </DrawerHeader>

            {RenderProfile()}

            <Box py="3" px="2">
              {menuItems.map((item, index) => (
                <Link href={item.url} key={index} _focus={{ boxShadow: 'none' }}>
                  <HoverBox icon={item.svg} altText={`${item.menuText} icon`}>
                    {item.menuText}
                  </HoverBox>
                </Link>
              ))}
            </Box>

            <DrawerFooter justifyContent="flex-start" height="100%">
              <Flex flexDirection="column" justifyContent="flex-end" height="100%">
                <Center>
                  <ExLink href={routes.facebook}>
                    <Image src="/icons/icon-facebook.svg" alt="facebook" w="8" h="8" m="2" />
                  </ExLink>
                  <ExLink href={routes.linkedin}>
                    <Image src="/icons/icon-linkedin.svg" alt="linkedin" w="8" h="8" m="2" />
                  </ExLink>
                  <ExLink href={routes.youtube}>
                    <Image src="/icons/icon-youtube.svg" alt="linkedin" w="8" h="8" m="2" />
                  </ExLink>
                </Center>

                <Center mt={3}>
                  <ExLink
                    href={routes.termsOfUse}
                    _focus={{ boxShadow: 'none' }}
                    mr="1"
                    textTransform="capitalize"
                    fontSize="xs"
                    alignItems="center"
                    borderRadius="base"
                    color="blue.800"
                  >
                    Terms Of Use
                  </ExLink>
                  |{' '}
                  <ExLink
                    href={routes.privacyPolicy}
                    _focus={{ boxShadow: 'none' }}
                    ml="1"
                    textTransform="capitalize"
                    fontSize="xs"
                    alignItems="center"
                    borderRadius="base"
                    color="blue.800"
                  >
                    Privacy Policy
                  </ExLink>
                </Center>

                <Text fontSize="xs" textAlign="left" mt={6}>
                  © Lifetime Group Limited |{' '}
                  <ExLink href="https://lifetime.co.nz" color="blue.800">
                    lifetime.co.nz
                  </ExLink>
                </Text>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </DrawerUI>
    </>
  )
}
export default Drawer
